<template>
  <p>EDIT FRO TEMPLATE</p>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
}
</script>
